function phoneNumberInput(input, onChange = () => {}) {
  const script = document.querySelector('.phone-number-script-js');

  const phoneNumber = intlTelInput(input, {
    preferredCountries: ['nl', 'de', 'fr', 'gb', 'us'],
    autoPlaceholder: 'off',
    utilsScript: script.getAttribute('data-utils'),
    formatOnDisplay: false,
  });

  const flagContainer = input.parentElement;
  const telInput = flagContainer.parentElement;

  Array
    .from(telInput.children)
    .filter(child => child !== flagContainer)
    .forEach(child => flagContainer.append(telInput.removeChild(child)));

  ['change', 'countrychange'].forEach(event => {
    input.addEventListener(event, ({ target }) => {
      target.value = phoneNumber.getNumber();
      onChange({ target });
    });
  });
}
